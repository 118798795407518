<template>
  <div class="slot" :class="available ? '' : 'disabled'">
    <label class="container"
      >{{ label }}
      <input type="checkbox" v-model="checked" @click="setSlotID" :disabled="!available" />
      <span class="checkmark"></span>
    </label>
    <div class="bookingStatus">{{ bookingStatus }}</div>
  </div>
</template>
<script>
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'OmRadio',
  props: {
    appointment: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { commit, state } = useStore();
    const checked = ref(false);
    const slot_id = computed(() => state.appointments.slot_id);
    const slot_ids = computed(() => state.appointments.slot_ids);

    const label = computed(() => {
      let start_time = new Date(props.appointment.start_time).getHours();
      start_time = start_time > 12 ? `${start_time - 12} pm` : `${start_time} am`;
      let end_time = new Date(props.appointment.end_time).getHours();
      end_time = end_time > 12 ? `${end_time - 12} pm` : `${end_time} am`;
      return `${start_time} - ${end_time}`;
    });

    const available = computed(() => (props.appointment.available ? true : false));

    const bookingStatus = computed(() => {
      if (!available.value) return 'Unavailable';
      if (checked.value) return 'Selected';
      if (props.appointment.technician_ids) return 'Booked';
      return 'Available';
    });

    const setSlotID = () => {
      if (checked.value) {
        commit('removeSlotID', props.appointment.id);
      } else {
        commit('addSlotID', props.appointment.id);
      }
    };

    watch(
      () => props.appointment,
      (value) => {
        checked.value = slot_ids.value.includes(value.id);
      },
      { deep: true },
    );

    watch(
      () => slot_ids.value,
      (value) => {
        checked.value = value.includes(props.appointment.id);
      },
      { deep: true },
    );

    onMounted(() => {
      checked.value = slot_ids.value.includes(props.appointment.id);
    });

    return {
      checked,
      label,
      available,
      bookingStatus,
      setSlotID,
      slot_id,
    };
  },
};
</script>
<style lang="scss">
.slot {
  border: 1px solid gray;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  background-color: #eee;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;

  .bookingStatus {
    font-size: 20px;
    font-weight: bold;
  }
}

.disabled {
  background-color: gray;
  cursor: move;

  .container {
    cursor: inherit;
    input,
    span {
      cursor: inherit;
    }
  }
}

.container {
  display: block;
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 1px solid gray;
  border-radius: 15px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: rgb(255, 165, 0);
  border: 1px solid rgb(255, 165, 0);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
