import Styled from 'vue3-styled-components';

const FileCardWrap = Styled.div`
  min-height: 625px;
  @media only screen and (max-width: 1199px){
    min-height: 100%;
  }
`;

export { FileCardWrap };
