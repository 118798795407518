<template>
  <!-- <a-modal
    v-model:visible="visible"
    title=""
    @ok="handleOk"
    @cancel="handleCancel"
    okText="Save"
    cancelText="Ignore"
    class="appointment-edit-modal"
  > -->
  <a-drawer
    title="Book an appointment"
    :width="'50%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    @close="handleCancel"
  >
    <Main>
      <sdCards>
        <template #button>
          <sdButton class="btn-outlined" size="small" outlined type="primary" @click="handleOk"> Save </sdButton>
        </template>
        <FileCardWrap>
          <BasicFormWrapper>
            <a-form name="multi-form" layout="horizontal">
              <a-row :gutter="30">
                <a-col :md="12" :xs="24" class="mb-25">
                  <a-form-item label="Customer">
                    <a-input
                      v-model:value="appointment.customer"
                      placeholder="Please type your customer name."
                      :disabled="hasOrder"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="12" :xs="24" class="mb-25">
                  <a-form-item label="Order ID">
                    <a-input
                      v-model:value="appointment.order_id"
                      placeholder="Please type your order id."
                      :disabled="hasOrder"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="30">
                <a-col :md="24" :xs="24" class="mb-25">
                  <a-form-item label="Note">
                    <a-input v-model:value="appointment.note" placeholder="Please type your note." />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="30">
                <a-col :md="12" :xs="24" class="mb-25">
                  <a-form-item label="Technician">
                    <a-select v-model:value="appointment.technician_id" :mode="!isEdit ? 'multiple' : 'default'">
                      <a-select-option
                        :value="technician.id"
                        :key="technician.id"
                        v-for="technician in technicianData"
                        >{{ technician.name }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="12" :xs="24" class="mb-25">
                  <a-form-item label="Internal booking">
                    <a-select v-model:value="appointment.internal_booking">
                      <a-select-option :value="1" :key="1">Internal</a-select-option>
                      <a-select-option :value="0" :key="0">External</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
              <div class="booking__component">
                <a-row :gutter="30">
                  <a-col :md="24" :xs="24" class="mb-25 arrows">
                    <span class="arrows__button" @click="prevWeek">&lt;</span>
                    <span class="arrows__text">{{ schedulePeriod }}</span>
                    <span class="arrows__button" @click="nextWeek">&gt;</span>
                  </a-col>
                </a-row>
                <a-row :gutter="30">
                  <a-col :md="24" :xs="24" class="mb-25 schedule-day-selector">
                    <div
                      v-for="date in weeks1[0]?.days"
                      :key="date.date"
                      class="schedule-day-selector__button"
                      @click="getAppointment(date.payload)"
                      :class="isSelected(date.payload) ? 'selected' : ''"
                    >
                      <div class="schedule-day-selector__button--day">{{ date.day }}</div>
                      <div class="schedule-day-selector__button--date">{{ date.date }}</div>
                    </div>
                  </a-col>
                </a-row>
                <a-row :gutter="30">
                  <a-col :md="24" :xs="24" class="mb-25">
                    <div v-if="isSaving || isLoading" class="spin">
                      <a-spin />
                    </div>
                    <div v-else>
                      <OmRaio v-for="appointment in appointments" :key="appointment.id" :appointment="appointment" />
                    </div>
                  </a-col>
                </a-row>
              </div>
            </a-form>
          </BasicFormWrapper>
        </FileCardWrap>
      </sdCards>
    </Main>
  </a-drawer>
  <!-- </a-modal> -->
</template>

<script>
import { FileCardWrap } from './style';
import { computed, onMounted } from 'vue';
import { BasicFormWrapper, Main } from '@/view/styled';
import OmRaio from './OmRadio.vue';
import { useStore } from 'vuex';
import dayjs from 'dayjs';

export default {
  name: 'AppointmentModal',
  components: { FileCardWrap, BasicFormWrapper, Main, OmRaio },
  props: {
    period: {
      type: Number,
      default: () => 5,
    },
    intervals: {
      type: Array,
      required: true,
      default: () => [
        {
          from: {
            hour: 8,
            minute: 0,
          },
          to: {
            hour: 20,
            minute: 0,
          },
        },
      ],
    },
    appointmentDuration: {
      type: Number,
      default: () => 30,
    },
    minWeeks: {
      type: Number,
      default: () => 1,
    },
  },
  setup(props) {
    const { dispatch, state, commit } = useStore();
    const weeks1 = computed(() => state.appointments.weeks);
    const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    const appointments = computed(() => {
      let appointments = state.appointments.appointments;
      console.log(appointments, 'appointments');
      return appointments.filter((appointment) => {
        return appointment?.start_time?.slice(11, 13) > 6 && appointment?.start_time?.slice(11, 13) < 20;
      });
    });
    const isSaving = computed(() => state.appointments.saving);
    const isLoading = computed(() => state.appointments.modalLoading);
    const today = computed(() => state.appointments.currentDate);
    const isEdit = computed(() => state.appointments.isEdit);
    const hasOrder = computed(() => state.appointments.hasOrder);

    const visible = computed(() => state.omni.showModal);
    const appointment = computed(() => state.appointments.appointmentData);
    const slotData = computed(() => state.appointments.slotData);
    const technicianData = computed(() => state.appointments.technicianData);
    console.log(appointment.value, 'appoint');
    const dates = [
      { day: 'WED', date: 25 },
      { day: 'TUE', date: 26 },
      { day: 'FRI', date: 27 },
      { day: 'SAT', date: 28 },
      { day: 'SUN', date: 29 },
    ];

    const nextWeek = () => {
      console.log(weeks1.value, 'weeks');
      let newWeek = weeks1.value.length
        ? dayjs(weeks1.value[weeks1.value.length - 1]?.week[0].schedule[props.period - 1]?.end).add(1, 'day')
        : dayjs();

      let weeks = [];
      let weekDays = [];
      for (let w = 0; w <= props.period - 1; w++) {
        let schedule = [];

        for (let workSchedule of props.intervals) {
          if (w === 0) {
            newWeek = newWeek.hour(workSchedule.from.hour).minute(workSchedule.from.minute).second(0);
          } else {
            newWeek = newWeek.add(1, 'day').hour(workSchedule.from.hour).minute(workSchedule.from.minute).second(0);
          }
          let currentSchedule = true;

          while (currentSchedule) {
            let start = newWeek.format('YYYY-MM-DD HH:mm:ss');
            newWeek = newWeek.add(props.appointmentDuration, 'minute');
            let end = newWeek.format('YYYY-MM-DD HH:mm:ss');

            schedule.push({ start, end });
            if (newWeek.hour() === workSchedule.to.hour && newWeek.minute() === workSchedule.to.minute) {
              currentSchedule = false;
            }
          }
        }

        weekDays.push({
          date: newWeek.date(),
          day: days[newWeek.day()],
          payload: new Date(newWeek.$d.toISOString().split('T')[0]).toISOString(),
        });
        weeks.push(schedule);
      }

      let schedule = {
        days: [],
        week: [],
      };
      for (let s = 0; s < weeks[0].length; s++) {
        let week = [];
        for (let w = 0; w <= props.period - 1; w++) {
          week.push(weeks[w][s]);
        }
        schedule.week.push({
          label: `${weeks[0][s].start.split(' ')[1].substring(-3, 5)} - ${weeks[0][s].end
            .split(' ')[1]
            .substring(-3, 5)}`,
          schedule: week,
        });
      }
      schedule.days = weekDays;
      commit('setWeeks', [schedule]);
      dispatch('fetchAppointment', weekDays[0].payload);
    };

    const prevWeek = () => {
      let newWeek = weeks1.value.length
        ? dayjs(weeks1.value[weeks1.value.length - 1]?.week[0]?.schedule[0]?.end).add(-props.period, 'day')
        : dayjs();
      // const startDayOfWeek = newWeek.day();

      let weeks = [];
      let weekDays = [];
      for (let w = 0; w <= props.period - 1; w++) {
        let schedule = [];

        for (let workSchedule of props.intervals) {
          if (w === 0) {
            newWeek = newWeek.hour(workSchedule.from.hour).minute(workSchedule.from.minute).second(0);
          } else {
            newWeek = newWeek.add(1, 'day').hour(workSchedule.from.hour).minute(workSchedule.from.minute).second(0);
          }
          let currentSchedule = true;

          while (currentSchedule) {
            let start = newWeek.format('YYYY-MM-DD HH:mm:ss');
            newWeek = newWeek.add(props.appointmentDuration, 'minute');
            let end = newWeek.format('YYYY-MM-DD HH:mm:ss');

            schedule.push({ start, end });
            if (newWeek.hour() === workSchedule.to.hour && newWeek.minute() === workSchedule.to.minute) {
              currentSchedule = false;
            }
          }
        }
        weekDays.push({
          date: newWeek.date(),
          day: days[newWeek.day()],
          payload: new Date(newWeek.$d.toISOString().split('T')[0]).toISOString(),
        });
        weeks.push(schedule);
      }

      let schedule = {
        days: [],
        week: [],
      };
      for (let s = 0; s < weeks[0].length; s++) {
        let week = [];
        for (let w = 0; w <= props.period - 1; w++) {
          week.push(weeks[w][s]);
        }
        schedule.week.push({
          label: `${weeks[0][s].start.split(' ')[1].substring(-3, 5)} - ${weeks[0][s].end
            .split(' ')[1]
            .substring(-3, 5)}`,
          schedule: week,
        });
      }
      schedule.days = weekDays;
      commit('setWeeks', [schedule]);
      dispatch('fetchAppointment', schedule.days[0].payload);
    };

    const schedulePeriod = computed(() => {
      const from = dayjs(state.appointments.weeks[0]?.days[0]?.payload).format('dddd DD, MMM');

      const to = dayjs(state.appointments.weeks[0]?.days[props.period - 1]?.payload).format('dddd DD');

      return `${from} - ${to}`;
    });

    const getAppointment = (date) => {
      commit('setCurrentDay', date);
      dispatch('fetchAppointment', date);
    };

    const initWeeks = () => {
      let min = props.minWeeks;
      do {
        nextWeek();
        min--;
      } while (min > 0);
    };

    const isSelected = (date) => {
      return today.value === date;
    };

    const handleCancel = () => {
      commit('setShowModal', false);
      commit('setChanged', false);
    };

    const onSave = () => {
      dispatch('setAppointment', appointment.value);
      //   push({ name: 'omni-appointments-list' });
      handleCancel();
      // commit('setHasOrder', false);
    };

    const handleOk = () => {
      onSave();
    };

    onMounted(async () => {
      initWeeks();
      let date = new Date(new Date().toISOString().split('T')[0]).toISOString();
      commit('setCurrentDay', date);
      dispatch('fetchAppointment', date);

      await dispatch('fetchSlotIDS', date);
      await dispatch('fetchTechnicians');
    });

    return {
      appointments,
      onSave,
      isSaving,
      isLoading,
      dates,
      nextWeek,
      prevWeek,
      weeks1,
      schedulePeriod,
      initWeeks,
      getAppointment,
      isSelected,
      appointment,
      slotData,
      technicianData,
      handleCancel,
      handleOk,
      visible,
      isEdit,
      hasOrder,
    };
  },
};
</script>
